<script>

import PasswordVisibilityToggle from "@/components/Login/PasswordVisibilityToggle.vue";
import { changePassword } from "@/services/session";

export default {
    name: 'ChangePassword',
    components: {
        PasswordVisibilityToggle
    },
    props: {

    },
    data() {
        return {
            loading: false,
            oldpassword: '',
            newpassword: '',
            confirmnewpassword: '',
            errorValidation: false,
            showCurrentPassword: false,
            showNewPassword: false,
            showConfirmNewPassword: false,
            errorMsg: '',
            // isPopupActive: false,
            titleCard: 'Password reset',
            textMsg: "Password reset successfully",
            saveBtnClicked: false,
            minLengthError: false,
            passwordsNotMatch: false,
            isOldPasswordCorrect: true,
        }
    },
    methods: {
        goBack() {
            this.$emit('toggleChangePassword');
        },
        showPasswordUpdateSuccessPopup() {
            this.$emit('showPasswordUpdateSuccessPopup');
        },
        async handleChangePassword() {
            try {
                if (this.loading) {
                    return;
                }
                this.saveBtnClicked = true;
                this.errorValidation = false
                this.errorMsg = ""
                if (this.newpassword !== this.confirmnewpassword) {
                    this.passwordsNotMatch = true;
                    return
                }
                if (this.oldpassword == "" || this.newpassword == "" || this.confirmnewpassword == "") {
                    this.errorValidation = true
                    this.errorMsg = "fill in all fields"
                    return
                }
                if (this.newpassword.length < 8) {
                    this.minLengthError = true;
                    return
                }
                this.loading = true;
                const res_data = await changePassword({
                    oldpassword: this.oldpassword,
                    newpassword: this.newpassword,
                });
                if (res_data.error === false) {
                    this.errorValidation = false;
                    this.errorMsg = "";
                    this.oldpassword = ""
                    this.newpassword = ""
                    this.confirmnewpassword = ""
                    this.$emit('toggleChangePassword');
                    this.showPasswordUpdateSuccessPopup();
                } else {
                    if (res_data?.message) {
                        // this.errorMsg = res_data.message;
                        this.isOldPasswordCorrect = false;
                        this.errorMsg = 'It seems that the current password is not correct';
                    } else {
                        this.errorValidation = true;
                        this.errorMsg = "An error occurred while updating the password.";
                    }
                }
            } catch (error) {
                this.errorValidation = true;
                this.errorMsg = "An error occurred while making the request.";
            } finally {
                this.loading = false;
            }

        },
        toggleShowCurrentPassword() {
            if (this.isCurrentPasswordNotEmpty) {
                this.showCurrentPassword = !this.showCurrentPassword;
            }
        },
        toggleShowNewPassword() {
            if (this.isNewPasswordNotEmpty) {
                this.showNewPassword = !this.showNewPassword;
            }
        },
    },
    computed: {
        isCurrentPasswordNotEmpty() {
            return this.oldpassword.length > 0;
        },
        isNewPasswordNotEmpty() {
            return this.newpassword.length > 0 || this.confirmnewpassword.length > 0;
        },
        isActiveSaveBtn() {
            return this.oldpassword.length > 0 && this.oldpassword.length > 0 && this.confirmnewpassword.length > 0;
        },
    },
}
</script>

<template>
    <form @submit.prevent="handleChangePassword()">
        <div class="form-container">

            <div class="form-content-container">
                <label class="subtitle pt-5 text-left color-purple" for="input-username">Current password</label>
                <b-form-input v-model="oldpassword" :type="showCurrentPassword ? 'text' : 'password'" placeholder=""
                    size="lg" maxlength="25" required
                    :class="['input w-100', !showCurrentPassword ? 'space-letter-pass' : '']">
                </b-form-input>

                <div class="eye-container" v-on:click="toggleShowCurrentPassword">
                    <PasswordVisibilityToggle :showPassword="showCurrentPassword" v-if="isCurrentPasswordNotEmpty" />
                </div>

                <div v-if="!isOldPasswordCorrect" class="no-correct-password">
                    <p class="old-password-msg error-msg">It seems that the current password is not correct</p>
                    
                    <!-- <h4 class="forgotten-password-text">Forgotten password?</h4> -->
                </div>

                <label class="subtitle mt-5 text-left color-purple" for="input-name">New password</label>

                <b-form-input :class="['input w-100', !showNewPassword ? 'space-letter-pass' : '']" v-model="newpassword"
                    :type="showNewPassword ? 'text' : 'password'" placeholder="" size="lg" maxlength="25"></b-form-input>

                <p :class="['create-password-text', minLengthError ? 'error-msg' : 'color-gray']">Create a
                    password with at least 8 characters.</p>

                <div class="eye-hide-container" v-on:click="toggleShowNewPassword">
                    <PasswordVisibilityToggle :showPassword="showNewPassword" v-if="isNewPasswordNotEmpty" />
                </div>

                <label class="subtitle text-left color-purple" for="input-new-password">Confirm new password</label>

                <b-form-input :class="['input w-100', !showNewPassword ? 'space-letter-pass' : '']"
                    v-model="confirmnewpassword" :type="showNewPassword ? 'text' : 'password'" placeholder="" size="lg"
                    maxlength="25"></b-form-input>
                <p v-if="passwordsNotMatch" class="error-msg">The passwords must match.</p>


                <label class="pt-5 text-left error-msg" v-if="errorValidation" for="input-confirm-password">{{ errorMsg
                }}</label>

                <div class="actions-btn-container">
                    <div class="action-btn">
                        <span @click="goBack" class="cancel-btn">Cancel</span>
                    </div>
                    <div class="action-btn">
                        <span @click="handleChangePassword"
                            :class="['save-btn', isActiveSaveBtn ? '' : 'no-active']">Save</span>
                    </div>
                </div>

            </div>
        </div>
    </form>
</template>


<style scoped>
.color-purple {
    color: var(--color-purple);
}

.form-content-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    /*background-color: #fff;*/
}

.form-content-container label {
    display: flex;
    justify-content: flex-start;
}

.old-password-msg {
    position: relative;
}

.input {
    border-radius: 0.2rem;
    font-size: 1.68rem;
    padding: 1.8rem 2.6rem;
    border: none;
    flex: 1;

    border-bottom: 0.1rem solid #dce2f0;
    border-top: 0;
    border-right: 0;
    border-left: 0;
}

.subtitle {
    font-size: 19px;
    font-family: Helvetica, Arial;
    font-weight: 300;
}

.eye-container,
.eye-hide-container {
    position: relative;
    left: 85%;
    width: 30px;
}

.eye-container {
    top: -3.5rem !important;
}

.eye-hide-container {
    top: -8.5rem !important;
}

.error-msg {
    color: red;
    font-size: 13px;
    font-family: Helvetica;
    font-weight: 300;
    text-align: left;
    margin-top: 3px;
}

.color-gray {
    color: #9b9b9b;
}

.action-btn span {
    font-size: 16px;
    cursor: pointer;
}

.cancel-btn {
    color: black;
}

.actions-btn-container {
    display: flex;
    justify-content: flex-end;
    gap: 6rem;
    margin-top: 3rem;
}

.create-password-text {
    font-size: 13px;
    font-family: Helvetica;
    font-weight: 300;
    text-align: left;
    margin-top: 3px;
    position: relative;
    margin-bottom: 3rem !important;
}

.space-letter-pass {
    letter-spacing: 3px;
}

.forgotten-password-text {
    text-align: end;
    cursor: pointer;
}

.no-correct-password {
    top: -1.5rem; 
    position: relative;
}
</style>