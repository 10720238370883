<script>
import PopUpCardModal from "@/components/shared/PopUpCardModal.vue";
import { getUserInfo } from "@/services/users";
import ChangePassword from '../components/ChangePassword.vue';

export default {
    name: 'MyAccount',
    components: {
        ChangePassword,
        PopUpCardModal
    },
    props: {},
    data() {
        return {
            titlePage: 'My Account',
            titleCard: 'Password change',
            textMsg: 'Your password has been successfully changed.',
            info: {
                email: null,
                name: null,
                image: null,
                role: null,
                subscribed: null,
            },
            isChangePassword: false,
        }
    },
    methods: {
        closePopUp() {
            this.$refs.modalSuccess.hide();
        },
        showPasswordUpdateSuccessPopup() {
            this.$refs.modalSuccess.showModal();
        },
        toggleChangePassword() {
            this.isChangePassword = false;
        },
        async getProfileInfo() {
            try {
                if (this.loadinginfo) {
                    return;
                }
                this.loadinginfo = true;
                const res_data = await getUserInfo();
                if (res_data.error === false) {
                    this.info = res_data.user;
                    this.info.email = res_data.user.email.toLowerCase();
                    this.info.name = res_data.user.name.toLowerCase();
                    this.info.google_id = res_data.user.google_id;
                } else {
                    this.info = { email: null, name: null, image: null, role: null };
                }
            } catch (error) {
                this.info = { email: null, name: null, image: null, role: null };
            } finally {
                this.loadinginfo = false;
            }
        },
        openEmailClient() {
            const email = "deactivation@alice.si";
            window.open(`mailto:${email}`, "_self");
        },
        handleToResetPasswordPage() {
            this.isChangePassword = true;
        },
    },
    mounted() {
        window.scrollTo(0, 0);
        this.getProfileInfo();
    },
}
</script>

<template>
    <div class="main-container">
        <div class="back">
            <router-link to="/">
                <img src="@/assets/BackArrow.svg" alt="back-arrow" /> Back
            </router-link>
        </div>
        <section class="section">
            <div class="section-container">
                <h1 class="title text-purple">{{ titlePage }}</h1>
                <div class="inputs-container">
                    <div class="input-container-email">
                        <h2 class="input-label text-purple">My email</h2>
                        <input disabled type="email" class="input-info-email" :placeholder="info.email" />
                    </div>

                    <div class="input-container-name">
                        <h2 class="input-label text-purple">My full name</h2>
                        <input disabled type="text" class="input-info-fullname" :placeholder="info.name" />
                    </div>

                    <div class="input-container-password text-purple" v-if="!info.google_id">
                        <h2 class="input-label">My password</h2>
                        <h4 v-if="!isChangePassword" @click="handleToResetPasswordPage" class="pt-4 change-pass-text">Change
                            password ></h4>
                        <!-- Inputs -->
                        <div v-else class="d-flex flex-column w-100">
                            <ChangePassword @toggleChangePassword="toggleChangePassword"
                                @showPasswordUpdateSuccessPopup="showPasswordUpdateSuccessPopup" />
                        </div>
                    </div>

                    <div class="input-container-deactivation">
                        <h2 class="input-label text-purple">Account deactivation</h2>
                        <h4 class="pt-4 email-deactivation-text">Email us at
                            <span @click="openEmailClient" class="text-purple">deactivation@alice.si</span>
                        </h4>
                    </div>
                </div>
            </div>
            <PopUpCardModal ref="modalSuccess" :isPopupActive="true" :closePopUp="closePopUp" :titleCard="titleCard"
                :textMsg="textMsg" :isOk="true" />
        </section>
    </div>
</template>

<style scoped>
.main-container {
    display: flex;
    flex-direction: column;
    margin: 6.2rem 0 0 6.2rem;
    padding: 4rem 1rem 0.2rem 8.2rem;
    max-width: 1440px;
}

.back {
    display: flex;
}

.back a {
    color: var(--color-dark-grey);
    font-size: 1.2rem;
}

.section-container {
    min-height: unset;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 4rem;
    margin-bottom: 7rem;
}

.title {
    font-family: Helvetica;
    font-weight: 400;
    font-size: 28px
}

.text-purple {
    color: var(--color-purple);
}

.inputs-container {
    display: flex;
    margin-top: 4rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 8rem;
}

[class^="input-container"] {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 400px;
}

.input-label {
    font-size: 24px;
    font-family: Helvetica;
    font-weight: 300;
}

.inputs-container input {
    background-color: #fff;
    width: 100%;
    height: 48px !important;
    padding: 0 2rem;
    border: none;
    border-radius: 0.2rem;
    font-size: 1.68rem;
    margin-top: 1.5rem;
}

.change-pass-text {
    font-family: Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 16px;
    cursor: pointer;
}

.input-container-deactivation span {
    cursor: pointer;
}

.input-info-fullname {
    text-transform: capitalize;
}
</style>